import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="filter"
export default class extends Controller {
	static targets = ["item", "form"];
	static classes = ["unselected", "selected"];
	static values = {
		name: { type: String, default: "filter" },
		selection: { type: Array, default: [] },
	};
	static outlets = ["autosubmit"];

	connect() {
		this.updateUI();
		this.updateForm();
	}

	toggle(event) {
		const key = event.params.key;

		if (this.selectionValue.includes(key)) {
			this.selectionValue = this.selectionValue.filter((item) => item !== key);
		} else {
			this.selectionValue = [...this.selectionValue, key];
		}

		this.updateUI();
		this.updateForm();
		this.submitOutlets();
	}

	clear() {
		this.selectionValue = [];
		this.updateUI();
		this.updateForm();
		this.submitOutlets();
	}

	updateUI() {
		this.itemTargets.forEach((item) => {
			if (this.selectionValue.includes(item.dataset.key)) {
				item.classList.add(...this.selectedClasses);
				item.classList.remove(...this.unselectedClasses);
			} else {
				item.classList.remove(...this.selectedClasses);
				item.classList.add(...this.unselectedClasses);
			}
		});
	}

	updateForm() {
		this.formTarget.replaceChildren();

		this.selectionValue.forEach((key) => {
			this.formTarget.insertAdjacentHTML("beforeend", `<input name="${this.nameValue}[]" value="${key}" type="hidden" class="hidden">`);
		});
	}

	submitOutlets() {
		this.autosubmitOutlets.forEach((autosubmit) => autosubmit.immediateSubmit());
	}
}
